import React from 'react'
import Picker from 'react-mobile-picker'


const Height = ({height ,setHeight }) => {
   
    const heightSelections = {
        height: Array.from({ length: 130 }, (_, i) => (i + 100).toString())
    }

    return (
        <div className="mt-[49px]">
            <h1 className="text-[40px] font-bold text-white font-aeonik">What Is Your Height ?</h1>
            <p className="text-[15px] text-white mt-2 font-euclid_circular">Share your height to fine-tune your fitness plan and
                progress tracking
            </p>

            <Picker value={height} onChange={setHeight} itemHeight={80} height={407} className='mt-[38px] mb-[45px]'>
                {Object.keys(heightSelections).map(name => (
                    <Picker.Column key={name} name={name} >
                        {heightSelections[name].map(option => (
                            <Picker.Item key={option} value={option} >
                                {({ selected }) => (
                                    <div style={{ color: selected ? 'white' : '#BAA5E1', fontSize: selected ? '80px' : '50px' }}>
                                        {option}
                                    </div>
                                )}

                            </Picker.Item>
                        ))}
                    </Picker.Column>
                ))}
            </Picker>

        </div>
    )
}

export default Height