import React, { useState, useEffect } from 'react'
import Gender from '../components/Questions/Gender'
import Age from '../components/Questions/Age'
import Weight from '../components/Questions/Weight'
import Height from '../components/Questions/Height'
import Goal from '../components/Questions/Goal'
import Activity from '../components/Questions/Activity'
import PopUp from '../components/Questions/PopUp'
import checkAuth from '../middleware/checkAuth'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'



const Quetions = () => {
    const [user, setUser] = useState(localStorage.user ? JSON.parse(localStorage.user) : null);
    const [activeSection, setActiveSection] = useState(0)
    const [gender, setGender] = useState('male')
    const [age, setAge] = useState({ age: '20' })
    const [weight, setWeight] = useState({ weight: '50' })
    const [height, setHeight] = useState({ height: '150' })
    const [goals, setGoals] = useState([])
    const [activityLevel, setActivityLevel] = useState(0)
    const [showPopUp, setShowPopUp] = useState(false)
    const [goalsDescription, setGoalsDescription] = useState([])
    const [levels, setLevels] = useState([])
    const nav = useNavigate()


    const getData = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/goals`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            setGoalsDescription(res.data)
            const resLevel = await axios.get(`${process.env.REACT_APP_API_URL}/api/activityLevel`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            setLevels(resLevel.data)
        } catch (err) {
            toast.error(err.response.data.err ?? 'Something wrong', { autoClose: 2000 })
        }
    }

    const setUserInfo = async () => {
        try {
            const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/user/${user._id}`, { gender, age: age.age, height: height.height, weight: weight.weight, goals  , activityLevel}, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.token}`
                }
            })
            setShowPopUp(true)
        } catch (err) {
            toast.error(err.response.data.err ?? 'Something wrong', { autoClose: 2000 })
        }
    }

    useEffect(() => {
        const authentication = async () => {
            let isAuth = await checkAuth()

            if (isAuth) {
                getData()
            } else {
                nav('/login')
            }
        }
        authentication()

    }, [])


    return (
        <div className="relative max-w-[500px] min-h-[100vh]  mx-auto flex justify-center items-center  overflow-hidden  " style={{
            background: `linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(106,62,187,1) 0%, rgba(59,35,104,1) 100%)`
        }}>
            <ToastContainer />
            <img src="./assets/matthew-sichkaruk-3qZt1MwF4Zo-unsplash.jpg" alt="background" className="absolute -z-10 object-cover w-full h-full" />
            <div className="w-[90%] min-h-[100vh]   font-aeonik ">
                <div className="flex justify-center items-center">
                    <img src="./assets/tryfit-logo.png" alt="TryFit logo" className="w-[158px] mt-[69px]" />
                </div>
                {activeSection == 0 && <Gender gender={gender} setGender={setGender} />}
                {activeSection == 1 && <Age age={age} setAge={setAge} />}
                {activeSection == 2 && <Weight weight={weight} setWeight={setWeight} />}
                {activeSection == 3 && <Height height={height} setHeight={setHeight} />}
                {activeSection == 4 && <Goal goals={goals} setGoals={setGoals} goalsDescription={goalsDescription} />}
                {activeSection == 5 && <Activity activityLevel={activityLevel} setActivityLevel={setActivityLevel} levels={levels} />}

                {showPopUp && <PopUp />}

                <div className='flex justify-between items-center mb-[112px]'>
                    <button className='text-[25px] w-[51px] h-[51px] rounded-full bg-[#674C98] text-white inline-flex justify-center items-center' onClick={() => setActiveSection(activeSection > 1 ? activeSection - 1 : 0)}><i className="fa-solid fa-arrow-left"></i></button>
                    <div className="flex justify-center items-center gap-5 mt-11 mb-14">
                        <div className={`w-[10px] h-[10px] rounded-full transition-all ease-in-out duration-100 ${activeSection == 0 ? 'bg-white border-2 border-[#6A3EBB] shadow-[0_0_0_2px_rgba(255,255,255,0.5)]' : 'bg-[#8764C8]'} `} onClick={() => setActiveSection(0)}></div>
                        <div className={`w-[10px] h-[10px] rounded-full transition-all ease-in-out duration-100 ${activeSection == 1 ? 'bg-white border-2 border-[#6A3EBB] shadow-[0_0_0_2px_rgba(255,255,255,0.5)]' : 'bg-[#8764C8]'} `} onClick={() => setActiveSection(1)}></div>
                        <div className={`w-[10px] h-[10px] rounded-full transition-all ease-in-out duration-100 ${activeSection == 2 ? 'bg-white border-2 border-[#6A3EBB] shadow-[0_0_0_2px_rgba(255,255,255,0.5)]' : 'bg-[#8764C8]'} `} onClick={() => setActiveSection(2)}></div>
                        <div className={`w-[10px] h-[10px] rounded-full transition-all ease-in-out duration-100 ${activeSection == 3 ? 'bg-white border-2 border-[#6A3EBB] shadow-[0_0_0_2px_rgba(255,255,255,0.5)]' : 'bg-[#8764C8]'} `} onClick={() => setActiveSection(3)}></div>
                        <div className={`w-[10px] h-[10px] rounded-full transition-all ease-in-out duration-100 ${activeSection == 4 ? 'bg-white border-2 border-[#6A3EBB] shadow-[0_0_0_2px_rgba(255,255,255,0.5)]' : 'bg-[#8764C8]'} `} onClick={() => setActiveSection(4)}></div>
                        <div className={`w-[10px] h-[10px] rounded-full transition-all ease-in-out duration-100 ${activeSection == 5 ? 'bg-white border-2 border-[#6A3EBB] shadow-[0_0_0_2px_rgba(255,255,255,0.5)]' : 'bg-[#8764C8]'} `} onClick={() => setActiveSection(5)}></div>
                    </div>
                    <button className='text-[25px] w-[93px] h-[51px] rounded-full bg-[#674C98] text-white inline-flex justify-center items-center' onClick={() => activeSection == 5 ? setUserInfo() : setActiveSection(activeSection + 1)}><i className="fa-solid fa-arrow-right"></i></button>
                </div>

            </div>
        </div>
    )
}

export default Quetions