import React from 'react'
import Picker from 'react-mobile-picker'


const Weight = ({weight ,setWeight }) => {
    const WeightSelections = {
        weight: Array.from({ length: 200 }, (_, i) => (i + 30).toString())
    }

    return (
        <div className="mt-[49px]">
            <h1 className="text-[40px] font-bold text-white font-aeonik">What Is Your Weight ?</h1>
            <p className="text-[15px] text-white mt-2 font-euclid_circular">Enter your weight to help us customize your workouts
                and track your progress.
            </p>

            <Picker value={weight} onChange={setWeight} itemHeight={80} height={407} className='mt-[38px] mb-[45px]'>
                {Object.keys(WeightSelections).map(name => (
                    <Picker.Column key={name} name={name} >
                        {WeightSelections[name].map(option => (
                            <Picker.Item key={option} value={option} >
                                {({ selected }) => (
                                    <div style={{ color: selected ? 'white' : '#BAA5E1', fontSize: selected ? '80px' : '50px' }}>
                                        {option}
                                    </div>
                                )}

                            </Picker.Item>
                        ))}
                    </Picker.Column>
                ))}
            </Picker>

        </div>
    )
}

export default Weight