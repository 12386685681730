import React, { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const nav = useNavigate()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const modalRef = useRef(null)
    const buttonRef = useRef(null)

    const handleModalToggle = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleSignOut = () => {
        setIsModalOpen(false)
        localStorage.clear()
        nav('/')
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
                setIsModalOpen(false);  // Close modal if the click is outside
            }
        };

        document.addEventListener('click', handleClickOutside);

        // Cleanup the event listener
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div className="w-[88%] m-auto mt-[69px]">
            <div className="w-full flex justify-between items-center">
                <div></div>
                <button type='button' className='text-white hidden '><i className="fa-solid fa-bars"></i></button>
                <img src="./assets/tryfit-logo.png" alt="TryFit logo" className="w-[96px] " />
                <button type='button' className='text-white ' onClick={handleModalToggle} ref={buttonRef}><i className="fa-solid fa-ellipsis-vertical"></i></button>
            </div>
            {isModalOpen && (
                <div className="absolute mt-2 right-0 bg-white p-4 rounded-lg w-40 shadow-lg z-50" ref={modalRef}>
                    <button
                        onClick={handleSignOut}
                        className=" text-red-500 w-full text-center "
                    >
                        Sign Out
                    </button>
                </div>)
            }
        </div>
    )
}

export default Header