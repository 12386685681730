import React from 'react';


const Goal = ({ goals, setGoals , goalsDescription }) => {
    const toggleGoal = (id) => {
        setGoals((prevGoals) =>
            prevGoals.includes(id)
                ? prevGoals.filter((goal) => goal !== id) 
                : [...prevGoals, id] 
        );
    };

    return (
        <div className="mt-[49px]">
            <h1 className="text-[37px] font-bold text-white">
                What Is Your Goal?
            </h1>
            <p className="text-[15px] text-white mt-2 font-euclid_circular">
                Set your fitness goals so we can create a plan that helps you achieve them faster.
            </p>
            <div className="my-[37px] space-y-[23px]">
                {goalsDescription.map(({ id, label }) => (
                    <div
                        key={id}
                        onClick={() => toggleGoal(id)}
                        className={`flex justify-between items-center px-[17.5px] py-[11.5px] transition-all duration-300 ease-in-out border rounded-[10px] font-aeonik cursor-pointer ${
                            goals.includes(id) ? 'bg-[#BAA5E1]' : ''
                        } border-[#BAA5E1]`}
                    >
                        <p className="text-white text-[20px]">{label}</p>
                        <div
                            className={`w-[22px] h-[22px] rounded-full flex justify-center items-center ${
                                goals.includes(id) ? 'bg-white text-[#BAA5E1]' : 'bg-[#BAA5E1]'
                            }`}
                        >
                            {goals.includes(id) && (
                                <i className="fa-solid fa-check"></i>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Goal