import React from 'react'
import Picker from 'react-mobile-picker'


const Age = ({ age, setAge }) => {
    const selections = {
        age: Array.from({ length: 83 }, (_, i) => (i + 18).toString())
    }

    return (
        <div className="mt-[49px]">
            <h1 className="text-[40px] font-bold text-white font-aeonik">How Old Are You ?</h1>
            <p className="text-[15px] text-white mt-2 font-euclid_circular">Tell us your age so we can personalize your fitness
                plan to match your stage and needs.
            </p>
            <Picker value={age} onChange={setAge} itemHeight={80} height={407} className='mt-[38px] mb-[45px]'>
                {Object.keys(selections).map(name => (
                    <Picker.Column key={name} name={name} >
                        {selections[name].map(option => (
                            <Picker.Item key={option} value={option} >
                                {({ selected }) => (
                                    <div style={{ color: selected ? 'white' : '#BAA5E1', fontSize: selected ? '80px' : '50px' }}>
                                        {option}
                                    </div>
                                )}
                            </Picker.Item>
                        ))}
                    </Picker.Column>
                ))}
            </Picker>
        </div>
    )
}

export default Age