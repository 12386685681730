import axios from "axios"
const checkAuth = async () => {
    const user = localStorage.user ? JSON.parse(localStorage.user) : null
    if (user) {
      try {
        const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/${user._id}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.token}`
          }
        })
        return true
      } catch(err) {
        return false
      }
    } else {
      return false
    }
}

export default checkAuth