import React from 'react'
import { motion } from 'framer-motion';


const Loading = () => {
    return (
        <div className="max-w-[500px] h-[100vh]  mx-auto flex justify-center items-center  relative  "
            style={{ background: `linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(186,165,225,1) 0%, rgba(106,62,187,1) 100%)` }}
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, ease: 'easeInOut', repeat: Infinity, repeatType: 'reverse' }}
        >
            <div className="w-[90%]" >
                <div className="flex justify-center items-center">
                    <img src="./assets/tryfit-logo.png" alt="TryFit logo" className="w-[480px]" />
                </div>
                <div className='flex justify-center mt-[225px] '>
                    <div className="spinner"></div>
                </div>
            </div>
        </div>
    )
}

export default Loading