import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useNavigate } from 'react-router-dom'



function Login() {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const nav = useNavigate()


    const signIn = () => {
        if (!email)
            return toast.error("Empty email field !", { autoClose: 2000 })
        if (!password)
            return toast.error("Empty password field !", { autoClose: 2000 })


        axios.post(`${process.env.REACT_APP_API_URL}/api/user/signIn`, {
            email: email,
            password: password
        })
            .then(res => {
                localStorage.clear()
                localStorage.setItem("user", JSON.stringify(res.data))
                if (res.data.takesQuestionnaire)
                    nav("/program")
                else
                    nav("/success-login")
            })
            .catch(err => {
                toast.error(err.response.data.err ?? 'Something wrong', { autoClose: 2000 })
            })
    }

    return (
        <div className="max-w-[500px] min-h-[100vh]  mx-auto flex justify-center items-center relative " style={{
            background: ` linear-gradient(180deg, rgba(49,50,82,0) 0%, rgba(106,62,187,1) 50%, rgba(106,62,187,1) 100%, rgba(106,62,187,1) 100%)`
        }}>
            <ToastContainer />
            <img src="./assets/gabin-vallet-YxCrQm9XNgg-unsplash.jpg" alt="background" className="absolute -z-10 object-cover w-full h-full" />
            <div className="w-[90%] h-full font-aeonik "  >
                <div className="flex justify-center items-center">
                    <img src="./assets/tryfit-logo.png" alt="TryFit logo" className="w-[158px] mt-[69px]" />
                </div>
                <div className="mt-[133px]">
                    <h1 className="text-[50px] font-bold text-white">Login</h1>
                    <p className="text-[15px] text-white mt-2">Ready to Crush Your Goals? Log In and <br />
                        Let's Go!
                    </p>
                    <div className="flex flex-col mt-[39px]">
                        <label htmlFor="email" className="text-[14px] text-[#B59FDD]">Email</label>
                        <input id="email" type="email" className="flex-1 bg-transparent outline-none text-[18px] text-white !border-b border-b-[#BDC4CC] py-[9px]" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="flex flex-col mt-[17px]">
                        <label htmlFor="password" className="text-[14px] text-[#B59FDD]">Password</label>
                        <input id="password" type="password" className="flex-1 bg-transparent outline-none text-[18px] text-white !border-b border-b-[#BDC4CC] py-[9px]" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <div className="flex justify-end mt-[39px]">
                        <p className="text-[14px] text-white font-euclid_circular">Forgot you password ?</p>
                    </div>
                    <div className="mb-[89px]">
                        <button type="button" className="block text-center w-full bg-[#BAA5E1] py-3 text-white text-[20px] rounded-[10px] mt-[42px]" onClick={signIn} >Login</button>
                        <Link to={'/sign-up'}><button type="button" className="block text-center w-full py-3 text-white text-[20px] rounded-[10px] border border-[#BAA5E1] mt-[22px]" >Sign up</button></Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
