import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import checkAuth from '../middleware/checkAuth'
import { useNavigate } from 'react-router-dom'



function Description() {
    const [activeSection, setActiveSection] = useState(0)
    const nav = useNavigate()

    useEffect(() => {
        const authentication = async () => {
            let isAuth = await checkAuth()

            if (isAuth) {
                
            } else {
                nav('/login')
            }
        }
        authentication()

    }, [])

    return (
        <div className="max-w-[500px] min-h-[100vh]  mx-auto flex justify-center items-center relative  " style={{
            background: ` linear-gradient(180deg, rgba(49,50,82,0) 0%, rgba(106,62,187,1) 50%, rgba(106,62,187,1) 100%, rgba(106,62,187,1) 100%)`
        }}>
            <img src="./assets/jonathan-borba-H6wTktsFxik-unsplash.jpg" alt="background"  className="absolute -z-10 object-cover w-full h-full" />
            <div className="w-[90%] h-full  font-aeonik">
                <div className="flex justify-center items-center">
                    <img src="./assets/tryfit-logo.png" alt="TryFit logo"  className="w-[158px] mt-[69px]" />
                </div>
                <div className="mt-[334px]">
                    <h1 className="text-[50px] font-bold text-white">Find the ideal <br />
                        workout</h1>
                    <p className="text-[15px] text-white mt-2 font-euclid_circular">tailored to your specific goals and preferences, <br />
                        ensuring you get the results you're looking for
                    </p>
                </div>
                <div className="flex justify-center items-center gap-5 mt-11 mb-14">
                    <div className={`w-[10px] h-[10px] rounded-full transition-all ease-in-out duration-100 ${activeSection == 0 ? 'bg-white border-2 border-[#6A3EBB] shadow-[0_0_0_2px_rgba(255,255,255,0.5)]' : 'bg-[#8764C8]'} `} onClick={() => setActiveSection(0)}></div>
                    <div className={`w-[10px] h-[10px] rounded-full transition-all ease-in-out duration-100 ${activeSection == 1 ? 'bg-white border-2 border-[#6A3EBB] shadow-[0_0_0_2px_rgba(255,255,255,0.5)]' : 'bg-[#8764C8]'} `} onClick={() => setActiveSection(1)}></div>
                    <div className={`w-[10px] h-[10px] rounded-full transition-all ease-in-out duration-100 ${activeSection == 2 ? 'bg-white border-2 border-[#6A3EBB] shadow-[0_0_0_2px_rgba(255,255,255,0.5)]' : 'bg-[#8764C8]'} `} onClick={() => setActiveSection(2)}></div>
                    <div className={`w-[10px] h-[10px] rounded-full transition-all ease-in-out duration-100 ${activeSection == 3 ? 'bg-white border-2 border-[#6A3EBB] shadow-[0_0_0_2px_rgba(255,255,255,0.5)]' : 'bg-[#8764C8]'} `} onClick={() => setActiveSection(3)}></div>
                </div>
                <div className="mb-[89px]">
                    <Link to={'/questions'}><button type="button" className="block text-center w-full py-3 text-white text-[20px] rounded-[10px] bg-[#BAA5E1] mt-[22px]" >Next</button></Link>
                </div>
            </div>
        </div>
    );
}

export default Description;
