import React from 'react'

const Gender = ({ gender, setGender }) => {
    return (
        <div className="mt-[49px]">
            <h1 className="text-[42px] font-bold text-white">Tell Us About You</h1>
            <p className="text-[15px] text-white mt-2 font-euclid_circular">Select your gender to customize your fitness journey
                just for you
            </p>
            <div className='mt-[103px] mb-[206px] flex justify-center gap-4'>
                <div className={`w-[163px] h-[189px] rounded-[10px] transition-all ease-in-out duration-300 ${gender == 'male' ? 'bg-[#BAA5E1]' : 'bg-[#23153E]'} flex justify-center items-center flex-col gap-3`} onClick={() => setGender('male')}>
                    <img src="./assets/male.png" width={96} height={69}  />
                    <p className='font-euclid_circular text-white text-[22px]'>Male</p>
                </div>
                <div className={`w-[163px] h-[189px] rounded-[10px] transition-all ease-in-out duration-300 ${gender == 'female' ? 'bg-[#BAA5E1]' : 'bg-[#23153E]'} flex justify-center items-center flex-col gap-3`} onClick={() => setGender('female')}>
                    <img src="./assets/female.png" width={96} height={69}  />
                    <p className='font-euclid_circular text-white text-[22px]'>Female</p>
                </div>
            </div>
        </div>
    )
}

export default Gender