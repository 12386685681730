import { useState, useEffect } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'


const CountdownTimer = ({ seconds, countDownFinich, message }) => {
  const [timeLeft, setTimeLeft] = useState(seconds);



  useEffect(() => {
    if (timeLeft <= 0) {
      countDownFinich()
      return;
    }// Stop if the timer reaches 0

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000)

    return () => clearInterval(timer); 
  }, [timeLeft]);

  return (
    <div className="flex flex-col items-center justify-center px-6 font-Montserrat text-white ">
      <div className="text-sm font-bold mb-2 text-center" dangerouslySetInnerHTML={{ __html: message }} ></div>
      <div className="font-Montserrat text-xl font-bold mt-3 ">
        <CountdownCircleTimer
          isPlaying
          duration={seconds}
          colors='#FFFFFF'
          size={120}
        >
          {({ remainingTime }) => remainingTime}
        </CountdownCircleTimer>
      </div>
    </div>
  );
};

export default CountdownTimer
