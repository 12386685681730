import React, { useEffect, useState } from 'react';
import NavBar from '../components/NavBar';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import checkAuth from '../middleware/checkAuth';
import Header from '../components/Header';

const Calendar = () => {
    const nav = useNavigate()
    const [user, setUser] = useState(localStorage.user ? JSON.parse(localStorage.user) : null);
    const { programId } = useParams();
    const [daysInMonth, setDaysInMonth] = useState([]);
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1); 
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [activities, setActivities] = useState([])
    const [program, setProgram] = useState({})
    const [activeSection, setActiveSection] = useState(0)

    const getDaysInMonth = (month, year) => {
        const date = new Date(year, month - 1, 1);
        const days = [];
        while (date.getMonth() === month - 1) {
            days.push(date.getDate());
            date.setDate(date.getDate() + 1);
        }
        setDaysInMonth(days);
    };

    const handlePreviousMonth = () => {
        if (activeSection != 0) {
            setCurrentMonth(12);
            setActiveSection((prev) => prev - 1)
        }
    }

    const handleNextMonth = () => {
        if (activeSection != program.calender.length - 1) {
            setActiveSection((prev) => prev + 1)
        }
    }

    const getMonthlyActivities = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/dailyActivity/${user._id}/monthActivity/${programId}/${activeSection}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.token}`
                }
            })
            setActivities(res.data)
        } catch (err) {
            toast.error(err.response.data.err ?? 'Something wrong', { autoClose: 2000 })
        }
    }

    const getData = async () => {
        try {

            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/program/${programId}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            setProgram(res.data)
        } catch (err) {
            toast.error(err.response.data.err ?? 'Something wrong', { autoClose: 2000 })
        }
    }


    useEffect(() => {
        const authentication = async () => {
            let isAuth = await checkAuth()
            if (isAuth) {
                getDaysInMonth(currentMonth, currentYear)
                getMonthlyActivities()
                getData()
            } else {
                nav('/login')
            }
        }
        authentication()

    }, [currentMonth, currentYear , activeSection])

    const checkActivityExists = (day , activeSection) => {
        const exists = activities.some(activity => {
            return activity.day === day && activity.sectionId == activeSection
        })
        return exists
    }


    return (
        <div className="relative max-w-[500px] min-h-[100vh] mx-auto overflow-hidden">
            <ToastContainer />

            <div className="absolute top-0 -z-10 w-full h-[50%] bg-white opacity-90">
                <img
                    src="./assets/victor-freitas-hOuJYX2K5DA-unsplash.jpg"
                    alt="background"
                    className="w-full object-cover opacity-100 blur-lg -z-10"

                />
            </div>

            <Header/>

            <div
                className="w-full rounded-t-[30px] min-h-[100vh] mt-[24px]"
                style={{
                    background: `linear-gradient(180deg, rgba(184,163,224,1) 0%, rgba(113,71,190,1) 100%)`,
                }}
            >
                {program?.calender?.length > 0 && <div className="w-[90%] pt-[63px] m-auto space-y-[35px]">
                    <div className="w-full pt-12 px-6 pb-6 bg-white rounded-[30px]">
                        <div className="flex justify-between items-center">
                            <button
                                type="button"
                                className="w-[30px] h-[30px] rounded-full flex justify-center items-center bg-[#B8A3E0] text-white"
                                onClick={handlePreviousMonth}
                            >
                                <i className="fa-solid fa-arrow-left"></i>
                            </button>
                            <h1 className="font-Montserrat font-semibold text-[#7F59C5] text-[28px]">
                                {program?.calender[activeSection].title}
                            </h1>
                            <button
                                type="button"
                                className="w-[30px] h-[30px] rounded-full flex justify-center items-center bg-[#B8A3E0] text-white"
                                onClick={handleNextMonth}
                            >
                                <i className="fa-solid fa-arrow-right"></i>
                            </button>
                        </div>

                        <div className="flex justify-center items-center mt-5 mb-7">
                            <div className="w-[120px] h-[18px] rounded-full bg-[#7F59C5]"></div>
                        </div>


                        <div className=" min-w-min flex justify-center gap-x-3 gap-y-5 flex-wrap">
                            {program.calender[activeSection].exercices.map((exercice, index) => (
                                <Link
                                    to={`/exercices/${programId}/${activeSection}/${index}`}
                                    key={index}
                                    className={`w-[37px] h-[37px] rounded-lg  ${checkActivityExists(index ,activeSection ) ? 'bg-[#7F59C5] text-white' : 'bg-[#E8DDFD] text-[#7F59C5] '}  flex justify-center items-center font-Montserrat text-base`}
                                >
                                    {index + 1}
                                </Link>
                            ))}
                        </div>

                    </div>

                    <div className="rounded-3xl bg-white pt-[15px] pb-[18px] flex justify-center items-center gap-9">
                        <p className="text-[#7F59C5] font-Montserrat text-[28px] font-semibold">
                            {activities.length} Days Streak
                        </p>
                        <img src="./assets/fire.png" alt="fire" />
                    </div>
                </div>}
            </div>
            <NavBar />
        </div>
    );
};

export default Calendar