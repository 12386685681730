import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import checkAuth from '../middleware/checkAuth';

const Home = () => {
    const nav = useNavigate()

    const logged = async () => {
        const isLogged = await checkAuth()
        if(isLogged)
            nav('/program')
    }
    useEffect(() => {
        logged()
    } , [])

    return (
        <div className="max-w-[500px] min-h-[100vh]  mx-auto flex justify-center items-center bg-[#5E37A6] bg-opacity-75 relative  " >
            <img src="./assets/andreea-boncota-icxORkp1Yv0-unsplash.jpg" alt="background" className="absolute -z-10 object-cover w-full h-full" />
            <div className="w-[90%] h-full font-aeonik" >
                <div className="flex justify-center items-center">
                    <img src="./assets/tryfit-logo.png" alt="TryFit logo" className="w-[480px] mt-[69px]" />
                </div>
                <div>
                    <h1 className="text-[50px] font-bold text-white mt-[222px]">Welcome</h1>
                    <p className="text-[15px] text-white mt-2 font-euclid_circular">to TryFit - Your Journey, Your Strength!</p>
                    <div className="mb-[89px]">
                        <Link to={'/sign-up'}><button type="button" className="block text-center w-full py-3 text-white text-[20px] rounded-[10px] bg-[#BAA5E1]  mt-[107px]" >Sign up</button> </Link>
                        <Link to={'/login'}><button type="button" className="block text-center w-full border-2 border-white py-3 text-white text-[20px] rounded-[10px] mt-[22px] " >Login</button></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home