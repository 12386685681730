import React from 'react'


const Activity = ({activityLevel ,setActivityLevel , levels }) => {
    return (
        <div className="mt-[183px]">
            <h1 className="text-[42px] font-bold text-white">Your Activity Level</h1>
            <p className="text-[15px] text-white mt-2 font-euclid_circular">Let us know your current physical activity level to tailor workouts that suit your lifestyle and help you reach your fitness goals
            </p>
            <div className="mt-[90px] mb-[48px] flex gap-[19px] overflow-x-auto">
                {levels.map((elt, index) => (
                    <div
                        key={index}
                        className={`w-[195px] h-[226px] border border-[#BAA5E1] rounded-[10px] px-6 pt-6 pb-[54px] transition-all ease-in-out duration-300 ${activityLevel == elt.id ? 'bg-[#BAA5E1] text-white ' : 'text-[#BAA5E1]'}  font-aeonik flex-shrink-0`}
                        onClick={() => setActivityLevel(elt.id)}
                    >
                        <div className="flex justify-end">
                            <div
                                className={`w-[22px] h-[22px] rounded-full flex justify-center items-center ${activityLevel == elt.id ? 'bg-[#5A359F] text-[#BAA5E1]' : 'bg-[#BAA5E1]'
                                    }`}
                            >
                                {activityLevel == elt.id && <i className="fa-solid fa-check"></i>}
                            </div>
                        </div>
                        <h1 className="text-[30px] font-bold">{elt.title}</h1>
                        <p className="text-xs mt-[10px]">{elt.description}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Activity