import React, { useEffect, useState, useRef } from 'react'
import NavBar from '../components/NavBar'
import { Link, useParams } from 'react-router-dom'
import checkAuth from '../middleware/checkAuth'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Loading from './Loading'
import Header from '../components/Header'



const Profile = () => {
    const [user, setUser] = useState(localStorage.user ? JSON.parse(localStorage.user) : null)
    const [userData, setUserData] = useState({})
    const [dataLoaded, setDataLoaded] = useState(false)
    const [password , setPassword] = useState("")
    const nav = useNavigate()

    const updateData = async () => {
        try {
            const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/user/profile/${user._id}`, {userName : userData.userName , email : userData.email , password} ,  {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            })
            setPassword("")
            toast(`Updated`, {autoClose : 1000 })
        } catch (err) {
            console.log(err)
            toast.error(err.response.data.err ?? 'Something wrong', { autoClose: 2000 })
        }
    }

    const getData = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/${user._id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            })
            setUserData(res.data)
            setDataLoaded(true)
        } catch (err) {
            console.log(err)
            toast.error(err.response.data.err ?? 'Something wrong', { autoClose: 2000 })
        }
    }

    useEffect(() => {
        const authentication = async () => {
            let isAuth = await checkAuth()
            if (isAuth) {
                getData()
            } else {
                nav('/login')
            }
        }
        authentication()

    }, [])


    return (
        dataLoaded ?
            <div className="relative max-w-[500px] min-h-[100vh]  mx-auto  overflow-hidden " style={{
            }}>
                <ToastContainer />

                <div className='absolute top-0 -z-10  w-full h-[50%] bg-white opacity-90'>
                    <img src="./assets/victor-freitas-hOuJYX2K5DA-unsplash.jpg" alt="background" className=" w-full object-cover opacity-100 blur-lg -z-10 " />
                </div>
                <Header />
                <div className='w-full rounded-t-[30px] h-[90vh] mt-[24px]' style={{ background: `linear-gradient(180deg, rgba(184,163,224,1) 0%, rgba(113,71,190,1) 100%)` }}>
                    <div className='w-[88%] mx-auto'>
                        <div className=' pt-[30px] space-y-[35px]' >
                            <h1 className='text-white font-euclid_circular font-bold text-[50px] mb-2'>Profile</h1>


                            <div className='mt-[30px] '>
                                <div className="flex flex-col mt-[39px]">
                                    <label htmlFor="username" className="text-[14px] text-[#FFFFFF]">Username</label>
                                    <input id="username" type="text" className="flex-1 bg-transparent outline-none text-[18px] text-white !border-b border-b-[#BDC4CC] py-[9px]" value={userData.userName} onChange={(e) => setUserData({...userData , userName : e.target.value})} />
                                </div>
                                <div className="flex flex-col mt-[39px]">
                                    <label htmlFor="email" className="text-[14px] text-[#FFFFFF]">Email</label>
                                    <input id="email" type="email" className="flex-1 bg-transparent outline-none text-[18px] text-white !border-b border-b-[#BDC4CC] py-[9px]" value={userData.email} onChange={(e) => setUserData({...userData , email : e.target.value})}  />
                                </div>
                                <div className="flex flex-col mt-[39px]">
                                    <label htmlFor="password" className="text-[14px] text-[#FFFFFF]">Password</label>
                                    <input id="password" type="password" className="flex-1 bg-transparent outline-none text-[18px] text-white !border-b border-b-[#BDC4CC] py-[9px]" value={password} onChange={(e) => setPassword(e.target.value)}  />
                                </div>
                                <button type="button" className="block text-center w-full bg-[#BAA5E1] py-3 text-white text-[20px] rounded-[10px] mt-[42px]" onClick={updateData} >Save</button>
                            </div>
                        </div>
                    </div>
                </div>
                <NavBar />
            </div>
            : <Loading />
    )
}

export default Profile