import React from 'react'
import { Link } from 'react-router-dom'

const PopUp = () => {
    return (
        <div className='absolute top-[139px] w-[90%] h-[80%] rounded-[20px] bg-opacity-35 backdrop-blur-[30px]  bg-white   py-12 px-[22px] transition-all ease-in-out duration-300'>
            <h1 className="text-[37px] font-bold text-white font-aeonik">Congratulations <br />You're all set!</h1>
            <p className="text-[15px] text-white mt-2 font-euclid_circular">Let's dive into your personalized fitness journey!
            </p>
            <img src="./assets/finish-line.png" alt="finish-line.png"  className='my-[86]' />
            <Link to={'/program'}><button type="button" className="block text-center w-full py-3 text-white text-[20px] rounded-[10px] bg-[#BAA5E1] mt-[22px]" >Finish</button></Link>
        </div>
    )
}

export default PopUp