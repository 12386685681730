import React, { useEffect, useState, useRef } from 'react'
import NavBar from '../components/NavBar'
import { Link, useParams } from 'react-router-dom'
import checkAuth from '../middleware/checkAuth'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Loading from './Loading'
import Header from '../components/Header'


const Exircices = () => {
    const { year, month, day, programId, exercicesList, section } = useParams()
    const [user, setUser] = useState(localStorage.user ? JSON.parse(localStorage.user) : null)
    const nav = useNavigate()
    const [exercices, setExercices] = useState([])
    const [program, setProgram] = useState({})
    const [dataLoaded, setDataLoaded] = useState(false)
    const [dailyActivity, setDailyActivity] = useState({})
    const [activeSectionExercices, setActiveSectionExercices] = useState({})


    const getData = async () => {
        try {
            const resProgram = await axios.get(`${process.env.REACT_APP_API_URL}/api/program/${programId}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            setProgram(resProgram.data)

            console.log('here : ' , programId, exercicesList, section )

            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/exercice/${programId}/${section}/${exercicesList}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            setExercices(res.data)

            const resActivity = await axios.get(`${process.env.REACT_APP_API_URL}/api/dailyActivity/${user._id}/dayActivity/${programId}/${section}/${exercicesList}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.token}`
                }
            })
            setDailyActivity(resActivity.data)
            setDataLoaded(true)
        } catch (err) {
            console.log(err)
            toast.error(err.response.data.err ?? 'Something wrong', { autoClose: 2000 })
        }
    }



    useEffect(() => {
        const authentication = async () => {
            let isAuth = await checkAuth()
            if (isAuth) {
                getData()
            } else {
                nav('/login')
            }
        }
        authentication()

    }, [])


    const goBack = () => {
        nav(`/calender/${programId}`)
    }

    return (
        dataLoaded ? <div className="relative max-w-[500px] min-h-[100vh]  mx-auto  overflow-hidden pb-[100px]" style={{
        }}>
            <ToastContainer />

            <div className='absolute top-0 -z-10  w-full h-[50%] bg-white opacity-90'>
                <img src="./assets/victor-freitas-hOuJYX2K5DA-unsplash.jpg" alt="background" className=" w-full object-cover opacity-100 blur-lg -z-10 " />
            </div>
            <Header />
            <div className='w-full rounded-t-[30px] min-h-[100vh] mt-[24px] pb-8' style={{ background: `linear-gradient(180deg, rgba(184,163,224,1) 0%, rgba(113,71,190,1) 100%)` }}>
                <div className='w-[88%] mx-auto'>
                    <div className=' pt-[30px] space-y-[35px]' >
                        <h1 className='text-white font-euclid_circular font-bold text-[50px] mb-2'>Workout</h1>
                        <div className='text-white flex justify-between items-center'>
                            <div>
                                <h3>{program.calender[section].title}</h3>
                                <h3>Day {parseInt(exercicesList) + 1}</h3>
                                <h3>{program.title}</h3>
                                <div className='flex gap-5 mb-3'>
                                    <p className='font-Montserrat text-sm flex justify-start items-center gap-3'>
                                        <img src="./assets/Group 2369.png" alt="" />
                                        {program.level}</p>
                                    {/* <p className='font-Montserrat text-sm flex justify-start items-center gap-3'>
                                    <img src="./assets/stopwatch.png" alt="" />

                                    {program.time}</p> */}
                                </div>
                            </div>
                            <div className='flex gap-7 text-2xl'>
                                {/* <button className=''><i className="fa-regular fa-bookmark"></i></button>
                                <button className=''><i className="fa-solid fa-bars"></i></button> */}
                            </div>
                        </div>

                        <div className='mt-[30px] '>
                            {exercices.map((elt, index) => <Link to={`/exercice/${elt.programId}/${section}/${exercicesList}/${elt.id}`}>
                                <div key={index} className='h-[100px] bg-white rounded-3xl overflow-hidden flex gap-4 mb-5'>
                                    <img src={`${process.env.REACT_APP_API_URL}${elt.image.path}`} alt="" className='min-w-[117px] h-full object-cover rounded-3xl' />
                                    <div className='w-full pt-4 pr-6'>
                                        <div className='flex justify-end mb-1'>
                                            <button className={`w-[15px] h-[16px]  ${dailyActivity?.exercices && dailyActivity?.exercices.includes(String(elt.id)) ? 'bg-[#44CAAC]' : 'bg-[#7F59C5]'} rounded-full border-2 border-white shadow-2xl`}></button>
                                        </div>
                                        <div className='font-Montserrat text-[#7F59C5] text-sm'>
                                            <h4>{elt.title}</h4>
                                            <p>{elt.sets} sets {elt.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>)}
                        </div>
                    </div>
                </div>
            </div>
            <NavBar />
        </div>
            : <Loading />
    )
}

export default Exircices